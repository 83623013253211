/* You can add global styles to this file, and also import other style files */

/* #fafafa */
:root {
  --light-gray-bg: #fafafa;
  --global-shadow: 0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color:#3d3d3d;
  /* background-color: var(--light-gray-bg) !important;  */
}

.mat-tab-label {
  border: 1px solid #333;
  /* Change this to your desired border style */
}



/* globals */
.m-0 {
  margin:0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-10 {
  padding-top:10px !important;
}

.pt-15 {
  padding-top:15px !important;
}

.pt-20 {
  padding-top:20px !important;
}

.col-50 {
  display: inline-flex;
  width:50%;
}

/* 50/50 col */
.flex-parent-row {
  display: flex;
  width: 100%;
}

.col-50-flex {
  flex: 1;
}

.w-33 {
  width:33% !important;
}

.w-50 {
  width:50% !important;
}

.d-ifx {
  display: inline-flex;
}

.inner-container {
  padding:0px 25px 25px 25px;
  /* overflow-x: hidden; */
}

.grouping-container {
  margin-right: 25px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

h1 {
  font-size: 24px;
  color:#3d3d3d;
  font-weight: 100;
}

h2 {
  font-size: 22px;
  color:#3d3d3d;
  font-weight: 100;
}

h3 {
  font-size: 20px;
  color:#3d3d3d;
  font-weight: 100;
}

h4 {
  font-size: 18px;
  color:#3d3d3d;
  font-weight: 100;
}

h5 {
  font-size: 16px;
  color:#3d3d3d;
  font-weight: 100;
}
/* ****** table styles ******** */
table {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
}

th {
  height:40px;
  box-shadow: 9px 5px 11px -20px rgba(0, 0, 0, 0.1) !important;
}

tr:hover {
  background: #f8f6f6;
}

tr:active {
  background: #dddddd !important;
}

td {
  border-bottom: solid 1px #e8e8e8;
}

.selectedRow {
  background: #dddddd !important;
}

.online {
  color: #A0CC44;
}

.offline {
  color: #cc6544;
}


/* cards */
.card {
  width: 100%;
  padding: 5px 5px;
  margin: 10px 0px;
  border-radius: 30px;
  box-shadow:  0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
  background-color: #ffffff !important;
}

.card-min-height {
  width: 100%;
  padding: 5px 5px;
  margin: 10px 0px;
  border-radius: 30px;
  box-shadow:  0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
  min-height: 180px;
  max-height: 180px;
  background-color: #ffffff !important;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 0 16px;
}

/* status cards */
.status-content-txt {
  color: #979797;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1px;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height:20px;
}

.footer-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:15px 25px;
  height:20px;
}


.dot {
  font-size: 30pt;
  line-height: 0.01;
  text-align: right;
  margin:0;
}

.good {
  .dot {
    color: #A0CC44;
  }

}

.warning {
  .dot {
    color: #D3C32D;
  }

}

.bad {
  .dot {
    color: #CC6544;
  }

}

.footer-txt {
  color: #979797;
  font-size: 12px;
  font-style: normal;
  margin-left: 5px;
  margin-bottom:13px;
}

.status-container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 50%;
}

.mat-mdc-card-header-text {
  width: 100% !important;
  display: flex;
}

.issue-list-container {
  padding: 0 16px;
}
